/* eslint-disable sonarjs/no-duplicate-string */
// References to external repositories:
// LINK field-validation-modal/src/pages/public/accounts/AccountsPages.tsx

const allForwardSlashRegex = /\//g;

/**
 * NOTE List down all needed paths here for React. This avoids automatic
 *      replacement of `/` pathname into `~2F`.
 */
const paths = [
  "/new",
  "/new/components",
  "/new/bills/:clientId",
  "/comparison-dashboard/:clientId",
  "/new/spending/:clientId",
  "/new/income/:clientId",
  "/admin/feature-repo",
  "/public/accounts/terms",
  "/wealth-speed/:clientId",
  "/wealth-dashboard/:clientId",
  "/my-property/:clientId",
  "/onboarding",
  "/admin/search/:clientId",
  "/admin/search",
  "/onboarding-tpc",
  "/my-knowledge/:clientId",
  "/transactionfeed/:clientId",
  "/transaction-rules-manager/:clientId",
  "/professional-services-overview/:clientId",
  "/professional-services/:contentId/:clientId",
  "/my-account/:clientId",
];

const pathnameAndUrlPatternPairs = [
  {
    pathname: "/goals/:clientId",
    urlPattern: "/goals/:clientId",
  },
  {
    pathname: "/signup",
    urlPattern: "/signup?name&email&user-type&messaging-id&invited&content",
  },
  {
    pathname: "/public/accounts/signup",
    urlPattern:
      "/public/accounts/signup?name&email&user-type&messaging-id&invited&content",
  },
  {
    pathname: "/invited",
    urlPattern: "/invited?name&email&user-type&messaging-id&invited&content",
  },
  {
    pathname: "/public/accounts/reset-password",
    urlPattern: "/public/accounts/reset-password?e&messaging-id&content",
  },
  {
    pathname: "/public/accounts/reset-password/repeat-request",
    urlPattern:
      "/public/accounts/reset-password/repeat-request?e&messaging-id&content",
  },
  {
    pathname: "/public/accounts/reset-password/success",
    urlPattern:
      "/public/accounts/reset-password/success?e&messaging-id&content",
  },
  {
    pathname: "/public/accounts/reset-password/expired",
    urlPattern:
      "/public/accounts/reset-password/expired?e&messaging-id&content",
  },
  {
    pathname: "/public/accounts/reset-password/change",
    urlPattern: "/public/accounts/reset-password/change?e&messaging-id&content",
  },
  {
    pathname: "/public/accounts/reset-security/ga",
    urlPattern: "/public/accounts/reset-security/ga?messaging-id&content",
  },
  {
    pathname: "/public/accounts/reset-security/confirm/ga",
    urlPattern:
      "/public/accounts/reset-security/confirm/ga?messaging-id&content",
  },
  {
    pathname: "/public/accounts/reset-security",
    urlPattern: "/public/accounts/reset-security?messaging-id&content",
  },
  {
    pathname: "/public/accounts/security-setup",
    urlPattern: "/public/accounts/security-setup?messaging-id&content",
  },
  {
    pathname: "/public/accounts/confirm-security",
    urlPattern: "/public/accounts/confirm-security?messaging-id&content",
  },
  {
    pathname: "/public/accounts/otp",
    urlPattern: "/public/accounts/otp?messaging-id&content",
  },
  {
    pathname: "/public/accounts/sms",
    urlPattern: "/public/accounts/sms?messaging-id&content",
  },
  {
    pathname: "/public/accounts",
    urlPattern: "/public/accounts?email&name&messaging-id&content",
  },
];

angular.module("meanApp").config(($stateProvider) => {
  pathnameAndUrlPatternPairs.forEach(({ pathname, urlPattern }) => {
    $stateProvider.state(pathname, {
      url: urlPattern,
      template: "",
      params: {
        clientId: {
          value: null,
          squash: false,
        },
      },
    });
  });

  paths.forEach((path) => {
    $stateProvider.state(path, {
      url: path,
      template: "",
      params: {
        clientId: {
          value: null,
          squash: false,
        },
      },
    });
  });
});
